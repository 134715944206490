import axios from '@axios';

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        fetchDoctors(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .get('/doctors', { params: queryParams })
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        },
        fetchDoctor(ctx, { id }) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/doctor/${id}/edit`)
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        },
        createDoctor(ctx, { payload }) {

            let formData = new FormData();
                
            formData.append('cvFile', payload.cvFile);
            formData.append('mainImage', payload.mainImage);

            Object.entries(payload).map(item => 
            {
                if (item[0] !== 'cvFile' && item[0] !== 'mainImage') formData.append(item[0], item[1]);
            });

            return new Promise((resolve, reject) => {
                axios
                    .post('/doctor/create', formData, {
                        headers: { 'Content-Type': 'multipart/form-data' }
                    })
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        },
        updateDoctor(ctx, { id, payload }) {

            let formData = new FormData();
                
            formData.append('cvFile', payload.cvFile);
            formData.append('mainImage', payload.mainImage);

            Object.entries(payload).map(item => 
            {
                if (item[0] !== 'cvFile' && item[0] !== 'mainImage') formData.append(item[0], item[1]);
            });

            return new Promise((resolve, reject) => {
                axios
                    .put(`/doctor/${id}/update`, formData, {
                        headers: { 'Content-Type': 'multipart/form-data' }
                    })
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        },
        desactivateDoctor(ctx, { id }) {
            return new Promise((resolve, reject) => {
                axios
                    .put(`/doctor/${id}/desactivate`)
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        },
        activateDoctor(ctx, { id }) {
            return new Promise((resolve, reject) => {
                axios
                    .put(`/doctor/${id}/activate`)
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        },
        deleteDoctor(ctx, { id }) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/doctor/${id}/delete`)
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        }
    }
}